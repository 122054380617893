<script setup>
import { defineProps, defineEmits, computed, ref, onBeforeMount } from 'vue';

import SesmtService from '../../../../../services/SesmtService';

const service = ref();

const value = computed({
    get: () => props.modelValue,
    set: (value) => emits('update:modelValue', value)
});

const emits = defineEmits(['update:modelValue']);

const props = defineProps({
    idValue: {
        type: Number
    },
    modelValue: {
        type: Object
    },
    recordsPerPage: {
        type: Number,
        default: 20
    },

    filtrosExtras: {
        type: Object
    }
});

onBeforeMount(() => (service.value = new SesmtService('/epi')));
</script>
<template>
    <AppOnePageDropdown
        v-model="value"
        :idValue="idValue"
        optionLabel="nome"
        placeholder="Selecione o EPC..."
        :service="service"
        :itemSize="recordsPerPage"
        :renderValue="(slotProps) => slotProps.value?.nome"
        :renderOption="(slotProps) => slotProps.option?.nome"
        :filtrosExtras="filtrosExtras"
    >
    </AppOnePageDropdown>
</template>
